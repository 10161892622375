<template>
  <a-spin :spinning="page_loading">
    <div class="wizard-side-border">
      <sdPageHeader :title="$t.paymentScreen" />
      <Main>
        <BasicFormWrapper class="basic-form-inner theme-light">
          <div class="atbd-form-checkout">
            <a-row justify="center">
              <a-col :xs="12">
                <div class="payment-method-form theme-light">
                  <div class="shipping-selection">
                    <div class="shipping-selection__card">
                      <sdCards headless>
                        <a-alert
                          v-if="show_page_error"
                          type="error"
                          :message="page_error_content"
                        />
                        <a-form
                          v-else
                          layout="vertical"
                        >
                          <a-form-item
                            name="number"
                            :label="`${$t.setPriceAmount} (Min: ${min_top_up_balance} TL)`"
                            class="infoPrice"
                          >
                            <a-input
                              v-model:value="cardInfo.total"
                              maxlength="10"
                              @keypress="isNumber($event)"
                            >
                              <template #suffix>
                                <a-tooltip title="T-Soft Kargo bloke tutarını değiştirme hakkını saklı tutar">
                                  <sdFeatherIcons
                                    type="alert-circle"
                                    size="18"
                                  />
                                </a-tooltip>
                              </template>
                            </a-input>
                          </a-form-item>
                          <a-form-item
                            name="number"
                            :label="$t.cardNumber"
                          >
                            <a-input
                              v-model:value="cardInfo.number"
                              placeholder="6547870269872527"
                              maxlength="16"
                              @keypress="isNumber($event)"
                            />
                          </a-form-item>
                          <a-form-item
                            name="name"
                            :label="$t.nameOnCard"
                          >
                            <a-input
                              v-model:value="cardInfo.name"
                              placeholder="Full name"
                              maxlength="50"
                            />
                          </a-form-item>

                          <a-row :gutter="16">
                            <a-col :xs="24" :sm="12">
                              <a-row :gutter="16">
                                <a-col :xs="12">
                                  <a-form-item
                                      name="month"
                                      initial-value=""
                                      :label="$t.moon"
                                  >
                                    <div class="ex-date-year-wrap d-flex">
                                      <a-select
                                          v-model:value="cardInfo.month"
                                          :style="{ width: '100%' }"
                                          show-search
                                      >
                                        <a-select-option
                                            v-for="(item, index) in 12"
                                            :key="index"
                                            :value="item"
                                        >
                                          {{ item }}
                                        </a-select-option>
                                      </a-select>
                                    </div>
                                  </a-form-item>
                                </a-col>
                                <a-col :xs="12">
                                  <a-form-item
                                      name="year"
                                      :label="$t.year"
                                  >
                                    <div class="ex-year">
                                      <a-select
                                          v-model:value="cardInfo.year"
                                          :style="{ width: '100%' }"
                                          show-search
                                      >
                                        <a-select-option
                                            v-for="(item, index) in 24"
                                            :key="index"
                                            :value="new Date().getFullYear() + index"
                                            show-search
                                        >
                                          {{
                                            parseInt(
                                                new Date()
                                                    .getFullYear()
                                                    .toString()
                                                    .slice(-2),
                                            ) + index
                                          }}
                                        </a-select-option>
                                      </a-select>
                                    </div>
                                  </a-form-item>
                                </a-col>
                              </a-row>
                            </a-col>
                            <a-col :xs="24" :sm="12">
                              <a-form-item
                                  name="cvv"
                                  label="CVV"
                              >
                                <div class="cvv-wrap">
                                  <a-input
                                      v-model:value="cardInfo.cvv"
                                      :style="{ width: '100%' }"
                                      placeholder="XXX"
                                      maxlength="4"
                                      @keypress="isNumber($event)"
                                  />
                                </div>
                              </a-form-item>
                            </a-col>
                          </a-row>



                          <a-form-item
                              name="name"
                              :label="__('reference', 'Referans')"
                          >
                            <a-input
                                v-model:value="cardInfo.referance"
                                placeholder=""
                                maxlength="50"
                            />
                          </a-form-item>



                          <a-form-item>
                            <a-button
                              class="btn-next"
                              type="primary"
                              :loading="form_submitting"
                              :style="{ width: '100%' }"
                              @click="sendPayment"
                            >
                              <sdFeatherIcons
                                type="check-circle"
                                size="16"
                                :style="{ margin: '3px' }"
                              />
                              {{ $t.payAndContinue }}
                            </a-button>
                          </a-form-item>
                        </a-form>
                      </sdCards>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </BasicFormWrapper>
      </Main>
    </div>
    <span v-html="sendFormFields" />
  </a-spin>
</template>

<script>
import { Main } from '../styled'

export default {
  name: 'MainPage',
  components: {
    Main,
  },
  data() {
    return {
      page_loading: false,
      show_page_error: false,
      page_error_content: '',
      form_submitting: false,
      sendFormFields: '',
      cardInfo: {
        number: '',
        name: '',
        month: '',
        year: '',
        cvv: '',
        total: 0,
      },
      min_top_up_balance: 0,
    }
  },
  mounted() {

    this.page_loading = true

    this.$restMethods.postForTSoftKargoWebApp('/top-up-balance-open', {}, { show_error: false }).then(response => {
      if ( ! response.hasError()) {

        const data = response.getData()

        this.min_top_up_balance = data.min_top_up_balance
        this.cardInfo.total = data.min_top_up_balance

      } else {

        this.show_page_error = true
        this.page_error_content = response.getErrorMessage()

      }

      this.page_loading = false

    })

    if (this.$route.query.state !== undefined) {
      if (this.$route.query.state === 'success') {
        this.$message.success(this.$t.theProvisionHasBeenSuccessfullyReceived)
        setTimeout(() => {
          this.$router.push({ name: 'T.PaymentContract.Page' })
        }, 1000)
      } else {
        this.$message.error(this.$route.query.fail_message ?? this.$t.paymentFailed)
      }
    }
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    checkForm() {
      if (this.cardInfo.total < this.min_top_up_balance) {
        this.$message.error(this.$t.youDidNotChooseAPackageMinLimit.replace('customerPrice', this.min_top_up_balance + ' TL'))
        return false
      }
      if (this.cardInfo.number.length < 16) {
        this.$message.error(this.$t.cardNumberMustBe16Digits)
        return false
      }
      if (this.cardInfo.name.length < 3) {
        this.$message.error(this.$t.cardholderNameMustBeAtLeast3Characters)
        return false
      }
      if (this.cardInfo.month < 1) {
        this.$message.error(this.$t.theExpirationMonthOfTheCardMustBeSelected)
        return false
      }
      if (this.cardInfo.year < 1) {
        this.$message.error(this.$t.theExpiryYearOfTheCardMustBeSelected)
        return false
      }
      if (this.cardInfo.cvv.length !== 3) {
        this.$message.error(this.$t.theCvvNumberOfTheCardMustBe3Digits)
        return false
      }
      return true
    },
    sendPayment() {

      const formValid = this.checkForm()
      if (formValid === false) {
        return
      }

      this.form_submitting = true
      this.$restMethods.postForWebApp('/send-payment', this.cardInfo).then(response => {
        if ( ! response.hasError()) {
          this.sendFormFields = response.getData().data
          setTimeout(() => {
            document.getElementById('sendPaymentForm').submit()
          }, 1000)
        } else {
          this.form_submitting = false
        }
      })

    },
  },
}
</script>
<style>
.packet-card-item {
  border-radius: 15px;
  padding: 30px 30px;
  -webkit-box-shadow: 0px 0px 44px -5px rgb(0 0 0 / 27%);
  -moz-box-shadow: 0px 0px 44px -5px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 14px -5px rgb(0 0 0 / 27%);
}

.packet-card-item .badge-wrap badge.pricing-badge {
  border-radius: 15px;
  padding: 6px 20px;
  background-color: #20c99733;
  color: #20c997;
  font-weight: 600;
}

.packet-card-item .price-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
  /*margin: 30px 0px;*/
}

.price-amount {
  line-height: 0;
}

.price-amount sub {
  font-size: 12px;
  color: #cccccc;
  font-weight: 300;
}

span.package-user-type {
  font-size: 11px;
  color: #cccccc;
  font-weight: 500;
}

span.package-user-type > span {
  font-size: 40px;
  color: #000000;
  font-weight: bold;
}

span.package-user-type > span > sup {
  font-weight: 500;
  font-size: 13px;
  color: #cccccc;
  margin-right: 2px;
}

.package-properties {
  line-height: 22px;
}

.package-orange {
  color: #ff6b00 !important;
  background-color: #ff6b0033 !important;
}

.btn-wrap > .package-orange {
  color: #ffffff !important;
  background-color: #ff6b00 !important;
}

.package-green {
  color: #20c997 !important;
  background-color: #20c99733 !important;
}

.btn-wrap > .package-green {
  color: #ffffff !important;
  background-color: #20c997 !important;
}

.package-blue {
  color: #8185f8 !important;
  background-color: #d7d8fa !important;
}

.btn-wrap > .package-blue {
  color: #ffffff !important;
  background-color: #8185f8 !important;
}

.package-pink {
  color: #ff80b4 !important;
  background-color: #fae4ed !important;
}

.btn-wrap > .package-pink {
  color: #ffffff !important;
  background-color: #ff80b4 !important;
}
</style>